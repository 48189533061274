import styled from "@emotion/styled"
import { Colors } from "@blueprintjs/core"
import { useDragLayer } from "react-dnd"

const PlaceholderBarUI = styled.div`
  ${(props: { barColor?: "green"; isDimmed?: boolean }) =>
    props.barColor === "green"
      ? `border: 1px solid ${Colors.FOREST4};`
      : `border: 1px solid #4580E6;`}
  pointer-events: none;
  position: absolute;
  height: calc(100% - 1.75rem); // Height of label, hacky
  margin-top: -0.25rem;
  ${(props) => props.isDimmed && `opacity: .25;`}

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: -0.5rem;
    left: -0.25rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    ${(props) =>
      props.barColor === "green"
        ? `background-color: ${Colors.FOREST4};`
        : `background-color: $#4580E6;`}
  }

  &:after {
    content: " ";
    display: block;
    position: absolute;
    bottom: -0.5rem;
    left: -0.25rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    ${(props) =>
      props.barColor === "green"
        ? `background-color: ${Colors.FOREST4};`
        : `background-color: #4580E6;`}
  }
`

type PlaceholderBarProps = {
  isOver: boolean
}

export default function PlaceholderBar({ isOver }: PlaceholderBarProps) {
  const { item: draggingItem } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
  }))

  const getBarColor = () => {
    switch (draggingItem?.metaPage.type) {
      case "instapage":
      case "instapagev2":
        return "green"
      default:
        return undefined
    }
  }

  if (isOver && draggingItem?.metaPage.type !== "document") {
    return <PlaceholderBarUI barColor={getBarColor()} />
  }
  return null
}
