import * as React from "react"

type WithLabelForItemTypeProps = {
  children: React.ReactElement<any, string>
  item: Record<string, any>
}

export default function WithLabelForItemType({
  children,
  item,
}: WithLabelForItemTypeProps) {
  let label =
    item.type === "instapage" || item.type === "instapagev2"
      ? "InstaPage"
      : item.type === "attachment"
        ? item.label
        : (item.type === "inserted_page" || item.type === "document") &&
            item.forSigning
          ? "Signature page"
          : (item.type === "inserted_page" || item.type === "document") &&
              item.isExecutedSignaturePage
            ? "Executed signature page"
            : null

  return React.cloneElement(children, { label })
}
