import { useDragLayer } from "react-dnd"

import PageLabel from "./PageLabel"
import WithLabelForItemType from "./WithLabelForItemType"

export default function DraggableLabel() {
  const {
    isDragging,
    item = {},
    initialLabelNodeOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialPointerOffset: monitor.getInitialClientOffset(),
    initialLabelNodeOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging || !initialLabelNodeOffset) return null

  const { x, y } = currentOffset as { x: number; y: number }

  const transform = `translate(${x}px, ${y}px)`

  return (
    <div
      style={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ transform, display: "inline-block" }}>
        <WithLabelForItemType item={item.metaPage}>
          <PageLabel type={item.metaPage.type} />
        </WithLabelForItemType>
      </div>
    </div>
  )
}
