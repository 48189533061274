import { v4 as uuid } from "uuid"

import { undoExecSigPageType } from "./mappings/thumbnails-to-pages"

function applyVersionToItem({ pages = [], placeholders = [] }, refId) {
  let placeholdersAtEndOfItem = placeholders
    .filter((p) => p.pageIndex === pages.length)
    .map((p) => ({ ...p.metaPage }))

  return [
    ...pages.reduce((versionPages, page) => {
      versionPages = [
        ...versionPages,
        ...placeholders
          .filter((p) => p.pageIndex === page.index)
          .map((p) => ({ ...p.metaPage })),
      ]

      let versionPage = {}

      if (!page.metaPage) {
        versionPage = {
          id: uuid(),
          type: "document",
          refId,
          refPageNumber: page.index + 1,
        }
      } else {
        versionPage = { ...page.metaPage }
      }

      return [...versionPages, versionPage]
    }, []),
    ...placeholdersAtEndOfItem,
  ]
}

function undoExecSigType({ pages = [], placeholders = [] }) {
  return {
    pages: pages.map((page) => ({
      ...page,
      metaPage: undoExecSigPageType(page.metaPage),
    })),
    placeholders: placeholders.map((placeholder) => ({
      ...placeholder,
      metaPage: undoExecSigPageType(placeholder.metaPage),
    })),
  }
}

export default function (input, refId) {
  return applyVersionToItem(undoExecSigType(input), refId)
}
