import { moveItemToIndex, moveKeyFromItemToItem } from "helpers/array"

import renderablePages from "./renderable-pages"

type TPageItem = {
  pageType: "page"
  index: number
  metaPage?: {
    id: string
  }
  url: string
}

type TPlaceholderItem = {
  pageType: "placeholder"
  index: number
  metaPage?: {
    id: string
  }
}

type PageData<I, T> = {
  pages: I[]
  placeholders: T[]
}

export default function moveMetaPage<
  I extends TPageItem,
  T extends TPlaceholderItem,
>(
  item: I | T,
  toIndexInput: number,
  { pages = [], placeholders = [] }: PageData<I, T>
): PageData<I, T> {
  let allPages = renderablePages(pages, placeholders)
  let prevPlaceholderSpots = allPages.filter(
    (p, pageIndex) => p.pageType === "placeholder" && pageIndex < toIndexInput
  ).length

  if (item.pageType === "placeholder") {
    if (item.index === toIndexInput || item.index + 1 === toIndexInput) {
      return {
        pages,
        placeholders,
      }
    }
  }

  const toIndex = Math.min(
    Math.max(0, toIndexInput - prevPlaceholderSpots),
    Math.max(0, pages.length - (item.pageType === "placeholder" ? 0 : 1))
  )

  // ======================================================================
  // PLACEHOLDER POSITION
  // ======================================================================
  // When dropping placeholder we need to know how many placeholders at the same index
  // to the left of the drop
  let positionDelta = allPages.filter((page, pageIndex) => {
    return (
      page.pageType === "placeholder" &&
      pageIndex < toIndexInput &&
      page.metaPage?.id !== item.metaPage?.id
    )
  }).length
  // ======================================================================

  if (item.pageType === "placeholder") {
    return {
      pages,
      placeholders: moveItemToIndex(
        placeholders,
        item,
        positionDelta,
        "metaPage.id"
      ).map((p) => {
        if (p.metaPage && p.metaPage.id === item.metaPage?.id) {
          return { ...p, pageIndex: toIndex }
        }
        return p
      }),
    }
  }

  return {
    pages: moveKeyFromItemToItem(
      pages,
      item,
      toIndex,
      "metaPage",
      "metaPage.id"
    ),
    placeholders,
  }
}
