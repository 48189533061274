import { useRef, ReactNode } from "react"

import { useDrop, useDragLayer } from "react-dnd"
import cx from "classnames"
import { TPage, TPlaceholder } from "./VersionForm"

type DroppableContainerProps = {
  index: number
  children?: ReactNode
  setHoverIndex?: (index: number) => void
  onCancel?: () => void
  onDrop?: (item: TPage | TPlaceholder) => void
  pageType?: string
  metaPage?: Record<string, any>
}

export default function DroppableContainer({
  children,
  index,
  onDrop = () => null,
  setHoverIndex = () => null,
  metaPage,
  pageType,
}: DroppableContainerProps) {
  const cardRef = useRef(null)

  function handleHover(item, monitor) {
    if (!cardRef.current) {
      return
    }

    if (item.type === "SIGNATURE_PAGE") {
      setHoverIndex(index)
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = cardRef.current?.getBoundingClientRect()

    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2

    const clientOffset = monitor.getClientOffset()

    const hoverClientX = clientOffset.x - hoverBoundingRect.left

    if (hoverClientX > hoverMiddleX) {
      setHoverIndex(index + 1)
    } else {
      setHoverIndex(index)
    }
  }

  const [, dropRef] = useDrop({
    accept: [
      "SIGNATURE_PAGE",
      "ATTACHMENT",
      "EXECUTED_SIGNATURE_PAGE",
      "INSTAPAGE",
      "INSTAPAGEV2",
    ],
    hover: handleHover,
    drop: onDrop,
    canDrop: (item) => {
      // NOTE cannot drop a signature page on a placeholder (attachment || executed)
      if (pageType === "placeholder" && item.type === "SIGNATURE_PAGE") {
        return false
      }

      // NOTE cannot drop a signature page on a signature page
      if (
        pageType === "page" &&
        item.type === "SIGNATURE_PAGE" &&
        (metaPage?.forSigning || metaPage?.isExecutedSignaturePage)
      ) {
        return false
      }

      return true
    },
  })

  const { isDragging, draggingItemType } = useDragLayer((monitor) => ({
    draggingItemType: monitor.getItem()?.type || "",
    isDragging: monitor.isDragging(),
  }))

  dropRef(cardRef)

  const classes = cx("px-2", {
    "z-10": isDragging && draggingItemType === "SIGNATURE_PAGE",
  })

  return (
    <div ref={cardRef} className={classes}>
      {children}
    </div>
  )
}
