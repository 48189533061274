import React, { useEffect } from "react"

import { useDocumentUpload } from "app/document-uploads"
import { ExtendedFile } from "components/add-documents/DocumentsQueue/DocumentUploadManager/operations"

type VersionUploaderProps = {
  children?: React.ReactElement
  files?: ExtendedFile[]
  transactionId: string
  isAddingFromDMS?: boolean
}

export default function VersionUploader({
  children,
  files,
  isAddingFromDMS,
  transactionId,
}: VersionUploaderProps) {
  const [uploads, uploadFile, clearUploads] = useDocumentUpload({
    transactionId,
    inQueue: false,
    isFromDMS: isAddingFromDMS,
    multiple: false,
    shouldPauseOnComplete: true,
  })

  useEffect(() => {
    files?.forEach(uploadFile)
  }, [files])

  useEffect(() => () => clearUploads(), [])

  let child = React.Children.only(children)

  if (uploads.length > 0 && child) {
    return React.cloneElement(child, {
      version: uploads[0],
    })
  }

  if (isAddingFromDMS) {
    return children
  }

  return null
}
