import DocumentQueueCard from "components/add-documents/DocumentsQueue/DocumentQueueCard"
import { Button } from "@blueprintjs/core"

import PageLoading1 from "icons/page-loading-1.svg"
import PageLoading2 from "icons/page-loading-2.svg"
import PageLoading3 from "icons/page-loading-3.svg"
import PageLoading4 from "icons/page-loading-4.svg"
import PageLoading5 from "icons/page-loading-5.svg"

const PageSkeletons = [
  PageLoading1,
  PageLoading2,
  PageLoading3,
  PageLoading4,
  PageLoading5,
]

type VersionLoadingProps = {
  version?: Record<string, any>
  onClose?: () => void
}

export default function VersionLoading({
  version,
  onClose = () => null,
}: VersionLoadingProps) {
  return (
    <>
      <div>
        {version.id && (
          <div className="mx-5 mb-3 mt-5">
            <DocumentQueueCard {...version} key={version.name} />
          </div>
        )}
        <div className="flex overflow-auto overflow-y-hidden pb-4 pl-16">
          {PageSkeletons.map((Skeleton, index) => (
            <div
              className="-mb-8 mr-3 mt-16 h-[350px] w-[260px] flex-shrink-0 scale-[1.35]"
              key={index}
            >
              <Skeleton />
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end rounded-b-sm bg-gray-50 p-4">
        <Button minimal onClick={onClose} className="mr-1">
          <div className="text-blue-9">Cancel</div>
        </Button>
      </div>
    </>
  )
}
