export default function toggleMetaPageType(item, pageData) {
  if (item.type === "document") {
    let placeholderIndex = pageData.pages.findIndex(
      (page) => page.metaPage?.id === item.id
    )

    return {
      pages: pageData.pages.map((page) => {
        if (page.metaPage?.id === item.id) {
          return { ...page, metaPage: undefined }
        }
        return page
      }),
      placeholders: [
        ...pageData.placeholders,
        {
          pageType: "placeholder",
          metaPage: { ...item, type: "inserted_page" },
          pageIndex: placeholderIndex + 1, // I think
        },
      ],
    }
  }

  // assuming this is always going to be a type of sig page
  if (item.type === "inserted_page") {
    let pageIndex =
      pageData.placeholders.find(
        (placeholder) => placeholder.metaPage?.id === item.id
      )?.pageIndex - 1 || 0

    return {
      pages: pageData.pages.map((page, index) => {
        if (index === pageIndex) {
          return { ...page, metaPage: { ...item, type: "document" } }
        }
        return page
      }),
      // Remove item placeholder
      placeholders: pageData.placeholders.filter(
        (placeholder) => placeholder.metaPage?.id !== item.id
      ),
    }
  }

  return pageData
}
