import { Button, ButtonProps } from "@blueprintjs/core"

import { useCurrentUser } from "features/auth/withAuth"

type DMSButtonProps = {
  onClick?: () => void
} & ButtonProps

export default function DMSButton({
  onClick = () => null,
  ...props
}: DMSButtonProps) {
  let { currentUser } = useCurrentUser()
  let buttonId =
    currentUser?.dmsType === "NET_DOCS"
      ? "upload-documents-button-netdocs"
      : currentUser?.dmsType === "IMANAGE"
        ? "upload-documents-button-imanage"
        : "upload-documents-button-dms"

  return (
    <Button {...props} onClick={onClick} id={buttonId}>
      {currentUser?.dmsType === "NET_DOCS" ? (
        <img src="/static/netdocs.png" />
      ) : currentUser?.dmsType === "IMANAGE" ? (
        <span className="flex items-center">
          <img className="mr-1 w-6" src="/static/imanage.png" />
          iManage
        </span>
      ) : (
        "From DMS"
      )}
    </Button>
  )
}
