import { VersionUploadRenderablePage } from "./renderable-pages"

type AllPagesWithStartAndEndNumbersType = {
  metaPage?: Record<string, any>
  url?: string
  pageType?: string
  position?: number
  startNumber: number
  endNumber: number
}[]

export default function getAllPagesWithStartAndEndNumbers(
  allPages: VersionUploadRenderablePage[]
) {
  return allPages.reduce<AllPagesWithStartAndEndNumbersType>(
    (acc, { metaPage, url, pageType, position }, index) => {
      const prevPage = acc[index - 1] || { endNumber: 0 }
      const countOfAssignments =
        metaPage && metaPage.assignments.length
          ? metaPage.assignments.length
          : 0
      const countOfAttachments =
        metaPage && metaPage.type === "attachment"
          ? metaPage.items
              .map((item: Record<string, any>) => item.pages.length)
              .reduce((a: number, b: number) => a + b, 0)
          : 0

      const startNumber = prevPage.endNumber + 1
      const endNumber =
        startNumber +
        Math.max(0, countOfAssignments - 1) +
        Math.max(0, countOfAttachments - 1)

      const pageWithPageNumbers = {
        metaPage,
        url,
        pageType,
        position,
        startNumber,
        endNumber,
      }

      return [...acc, pageWithPageNumbers]
    },
    []
  )
}
